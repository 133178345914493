import React from 'react'
import { ImageFragment } from '../src/model/fragments'
import { Arrow } from './Arrow'
import { FlexiLink, FlexiLinkProps } from './FlexiLink'
import { Picture, PictureEncode, PictureProps } from './Picture'

interface CategoryImageProps {
	FlexiLinkProps: FlexiLinkProps
	title: string
	image?: ImageFragment
	noRadius?: boolean
	mode?: 'default' | 'square' | 'rect'
}

export const CategoryImage: React.FunctionComponent<CategoryImageProps> = (
	props,
) => {
	const { FlexiLinkProps, title, image, noRadius, mode = 'default' } = props

	const [width, height, pictureMode] = React.useMemo(() => {

		switch(mode)
		{
			case 'default' : return [230, 160, 'fill'];
			case 'square' : return [390, 390, 'fill'];
			case 'rect' : return [790, 390, 'fill'];
		}

	}, [mode]);

	const [mwidth, mheight, mpictureMode] = React.useMemo(() => {

		switch(mode)
		{
			case 'default' : return [230, 160, 'fill'];
			case 'square' :
			case 'rect': return [390, 390, 'fill']; // for mobile, we are transforming rects to squares
		}

	}, [mode]);

	return (
		<FlexiLink {...FlexiLinkProps}>
			<a
				aria-label={title}
				className="categoryImage"
				style={{
					borderRadius: noRadius ? '0px' : undefined,
				}}
			>
				{image && (mwidth != width || mheight != height) && (
					<>
						<div className='categoryImage-pictureDesktop'>
							<Picture image={image} width={width} height={height} alt={''} mode={pictureMode as 'fill' | 'cover'} />
						</div>

						<div className='categoryImage-pictureMobile'>
							<Picture image={image} width={mwidth} height={mheight} alt={''} mode={mpictureMode as 'fill' | 'cover'} />
						</div>
					</>
				)}

				{image && !(mwidth != width || mheight != height) && (
					<>
						<div>
							<Picture image={image} width={width} height={height} alt={''} mode={pictureMode as 'fill' | 'cover'} />
						</div>
					</>
				)}
				<div className="categoryImage-in">
					<h3 className="categoryImage-title">{title}</h3>
					<Arrow />
				</div>
			</a>
		</FlexiLink>
	)
}
