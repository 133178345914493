import cn from 'classnames'
import React from 'react'
import { useCurrentLocale } from '../../src/contexts/CurrentLanguageContext';
import { useTranslate } from '../../src/hooks/useTranslate';
import { axiosPost } from '../../src/utils/axiosWrapper';
import SvgSearch from '../icons/SvgSearch';
import { FulltextHits, FulltextHitsProps } from './FulltextHits';
import { FulltextSearchBox } from './FulltextSearchBox';

export interface NavSearchProps {}


export const NavSearch: React.FunctionComponent<NavSearchProps> =
	() => {
		const [isOpen, setIsOpen] = React.useState(false)
		const translate = useTranslate();
		const locale = useCurrentLocale();

		const onClick = React.useCallback(() => {
			setIsOpen(!isOpen)
		}, [isOpen])

		const [queryString, setQueryString] = React.useState<string>('');
		const [data, setData] = React.useState<FulltextHitsProps | null>(null);

		React.useEffect(() => {
			if(!isOpen)
			{
				setQueryString('');
			}
		}, [isOpen]);

		React.useEffect(() => {

			if(queryString.length >= 3)
			{
				(axiosPost('/fulltext/autocomplete', {q: queryString, locale: locale}) as Promise<FulltextHitsProps>)
				.then(res => setData(res));
			}
			else 
			{
				setData(null);
			}

		}, [queryString]);

		const onBlur = () => {
			setTimeout(() => {
				setIsOpen(false);
			}, 100);
		};

		return (
			<div className={cn('navSearch', isOpen && 'is-open')}>
				{ isOpen && <div className='navSearch-overlay' onBlur={onBlur}>
					<div className="navSearch-bar">
						<FulltextSearchBox onSubmit={onBlur} queryString={queryString} setQueryString={setQueryString} autofocus={true} />
						<div className="navSearch-hits">
						{
							data && <FulltextHits {...data} />
						}
						</div>
					</div>
				</div> }
				<div className="navSearch-wrapper" onClick={onClick}>
					<div className="navSearch-icon">
						{ <SvgSearch /> }
					</div>
					<div className="navSearch-options">
						{ translate('search') }
					</div>
				</div>
			</div>
		)
	}
